import React, { MutableRefObject } from 'react';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';
import { MdOutlineApproval } from 'react-icons/md';
import { PiFileMagnifyingGlass } from 'react-icons/pi';
import { Bottom, Page, Top } from './ReportLayout';

const ReportPage11 = ({ refs }: { refs: MutableRefObject<(HTMLDivElement | null)[]> }) => {
  return (
    <Page refs={refs} index={10}>
      <Top>
        <h1 className="text-primary font-sans text-3xl font-black uppercase">Quality Assurance</h1>
      </Top>

      {/* page body */}
      <div className="mx-6 mt-28 flex flex-row gap-6">
        {/* left col */}
        <div className="flex w-1/2 flex-col gap-2">
          <div className="flex flex-col">
            <h3 className="font-sans text-[10px] font-black uppercase leading-[16px]">Committed To Quality</h3>
            <p className="text-[10px] leading-[16px]">
              As a business we are committed to quality, safety and customer care. By selecting our professional
              accredited business, you can be confident that your home improvement installation is in safe and capable
              hands. As members of several respected accreditation schemes, we promise to ensure the quality and
              efficiency of your home upgrade.
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <h3 className="font-sans text-[10px] font-black uppercase leading-[16px]">Being Accredited Means</h3>
            <div className="flex flex-row items-start gap-2">
              <div className="flex h-auto w-6 min-w-6 items-start">
                <AiOutlineSafetyCertificate className="size-full" />
              </div>
              <p className="text-[10px] leading-[16px]">
                We have undergone rigorous training and certification processes which equips us with the knowledge and
                skills to complete work safely and to legally required standards.
              </p>
            </div>
            <div className="flex flex-row items-start gap-2">
              <div className="flex h-auto w-6 min-w-6 items-start">
                <PiFileMagnifyingGlass className="size-full" />
              </div>
              <p className="text-[10px] leading-[16px]">
                We are regularly assessed to provide assurance of ongoing competence.
              </p>
            </div>
            <div className="flex flex-row items-start gap-2">
              <div className="flex h-auto w-6 min-w-6 items-start">
                <MdOutlineApproval className="size-full" />
              </div>
              <p className="text-[10px] leading-[16px]">
                We hold the necessary insurances and have the right processes and procedures to assure compliance.
              </p>
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="font-sans text-[10px] font-black uppercase leading-[16px]">
              What happens if I'm not happy with the work?
            </h3>
            <div className="flex flex-col gap-2">
              <p className="text-[10px] leading-[16px]">
                We aim to ensure you are 100% happy with the work we carry out however we also recognise that sometimes
                things can go wrong.
              </p>
              <p className="text-[10px] leading-[16px]">
                <b>We guarantee our workmanship for 2 years</b> from the date of installation.
              </p>
              <p className="text-[10px] leading-[16px]">
                Products supplied by us come with the benefit of a <b>manufacturer's product guarantee</b>. Where a
                claim in respect of any of the products is notified to us by you in accordance with our terms and
                conditions, we will liaise with the manufacturer and use all reasonable endeavours to secure a
                replacement of the product (or the part in question), or a refund of the price of the product (or a
                proportionate part of the price).
              </p>
              <p className="text-[10px] leading-[16px]">
                Lastly, we will provide you with <b>Insurance Backed Guarantees</b> for all measures installed. The
                insurance policy provides you with protection and cover should we cease to trade and there is an issue
                with your installation.
              </p>
            </div>
          </div>
        </div>
        {/* right col */}
        <div className="flex w-1/2 flex-col gap-3">
          <div className="flex flex-col items-start gap-1">
            <img
              src="/images/quality-assurance/trustmark.png"
              alt="trustmark logo"
              className="max-h-16 max-w-32 object-contain"
            />
            <p className="text-[10px] leading-[16px]">
              Trustmark is the only UK government-endorsed quality scheme for home improvement work. It is designed to
              protect customers and ensure high standards of workmanship.
            </p>
          </div>
          <div className="flex flex-col items-start gap-1">
            <img src="/images/quality-assurance/mcs.png" alt="mcs logo" className="max-h-16 max-w-32 object-contain" />
            <p className="text-[10px] leading-[16px]">
              MCS is a mark of quality, which demonstrates we have adhered to recognised industry standards when
              completing your renewable installation - highlighting quality, competency and compliance.
            </p>
          </div>
          <div className="flex flex-col items-start gap-1">
            <img
              src="/images/quality-assurance/niceic.png"
              alt="niceic logo"
              className="max-h-16 max-w-32 object-contain"
            />
            <p className="text-[10px] leading-[16px]">
              NICEIC registered tradespeople are regularly assessed to ensure their electrical installation work meets
              the relevant standards and legislation expected to keep you safe.
            </p>
          </div>
          <div className="flex flex-col items-start gap-1">
            <img
              src="/images/quality-assurance/iaac.png"
              alt="iaac logo"
              className="max-h-16 max-w-32 object-contain"
            />
            <p className="text-[10px] leading-[16px]">
              The IAA framework gives you the confidence that insulation work is carried out to the highest standards
              and subject to a level of rigor and surveillance which helps ensure it is done 'Right first time'.
            </p>
          </div>
          <div className="flex flex-col items-start gap-1">
            <img
              src="/images/quality-assurance/hies.png"
              alt="hies logo"
              className="max-h-16 max-w-32 object-contain"
            />
            <p className="text-[10px] leading-[16px]">
              HIES is a leading consumer protection organisation covering the installation of home energy products.
              Using a HIES member offers you additional protection in the event of an issue with the installation.
            </p>
          </div>
        </div>
      </div>

      <Bottom title="Quality Assurance" />
    </Page>
  );
};

export default ReportPage11;
