import { toast } from 'react-toastify';
import axios from 'axios';
import { appInsightsManager } from '../components';

export const initApi = (baseUrl: string) => {
  axios.defaults.baseURL = baseUrl;
};

export const initToken = (token: string | null) => {
  axios.defaults.headers.common['Authorization'] = token;
};

export const getToken = () => {
  return axios.defaults.headers.common['Authorization'];
};

export type ErrorLogRequest = {
  message: string;
  stackTrace?: string;
};

export type AxiosErrorData = {
  errors: unknown;
  detail: string;
  url: string;
  status?: string;
};

const handleError = async (error: unknown) => {
  if (axios.isAxiosError(error)) {
    throw {
      errors: error.response?.data.errors,
      status: error.response?.data.status,
      detail: error.response?.data.detail,
      url: error.config?.url,
    };
  } else {
    await api.logError(error as Error);
  }
};

export const api = {
  get: async (endpoint: string) => {
    try {
      const response = await axios.get(endpoint);
      return response.data;
    } catch (err: unknown) {
      return await handleError(err);
    }
  },
  getFile: async (endpoint: string, defaultFileName: string) => {
    try {
      const response = await axios.get(endpoint, { responseType: 'arraybuffer' });
      const matches = response.headers['content-disposition'].match(/filename[^;=\n]*=((['"])(?:(?!\2)[\s\S])*?)\2/);
      let fileName = defaultFileName;
      if (matches && matches.length > 1) {
        const encodedFilename = matches[1];
        fileName = decodeURIComponent(encodedFilename.replace(/^["']/, ''));
      }
      return { file: response.data, fileName };
    } catch (err: unknown) {
      return await handleError(err);
    }
  },
  post: async <T>(endpoint: string, data?: T) => {
    try {
      const response = data ? await axios.post(endpoint, data) : await axios.post(endpoint);
      return response;
    } catch (err: unknown) {
      return await handleError(err);
    }
  },
  put: async <T>(endpoint: string, data?: T) => {
    try {
      const response = data ? await axios.put(endpoint, data) : await axios.put(endpoint);
      return response;
    } catch (err: unknown) {
      return await handleError(err);
    }
  },
  patch: async <T>(endpoint: string, data?: T) => {
    try {
      const response = await axios.patch(endpoint, data);
      return response;
    } catch (err: unknown) {
      return await handleError(err);
    }
  },
  delete: async (endpoint: string) => {
    try {
      const response = await axios.delete(endpoint);
      return response;
    } catch (err: unknown) {
      return await handleError(err);
    }
  },
  logError: async (error: Error) => {
    if (process.env.NODE_ENV !== 'development') {
      const appInsights = appInsightsManager.getAppInsights();
      if (appInsights) {
        appInsights.trackException({ exception: error });
      }
    } else {
      console.log(error);
    }
    toast.error(
      'An unknown error occured. Please try again and contact support on 0800 058 4140 if the error persists.'
    );
    return;
  },
};

export const clearSWRError = (mutate: (data: () => void, options: boolean) => void) => mutate(() => null, false);

export const handleUnknownDetail = (error: unknown) => {
  const { detail, url } = error as AxiosErrorData;
  if (detail) {
    api.logError({ message: detail, stack: url } as Error);
  }
};
