import { RefundReason } from '@epcbuilder/lib/models/payments';
import { api, formatUtcDatetime } from '@epcbuilder/lib/utils';
import { SurveyBookingForm, SurveySaveBooking } from '@/models/job';

export const getSurveyBooking = async ({ id }: { id: string }) => {
  return await api.get(`/survey/${id}/booking`);
};

export const getSurveyStatuses = async () => {
  return await api.get(`/survey/statuses`);
};

export const postSurveyStatus = async ({ id, surveyStatusId }: { id?: string; surveyStatusId: number }) => {
  return await api.post(`/survey/${id}/status`, { surveyStatusId });
};

export const postSurveyBooking = async ({ id, data }: { id?: string; data: SurveySaveBooking }) => {
  return await api.post(`/survey/${id}/booking`, data);
};

export const deleteSurveyBooking = async ({ id }: { id: string }) => {
  return await api.delete(`/surveybooking/${id}`);
};

export const postSurveyComplete = async ({ id, data }: { id?: string; data: { actualRating: string } }) => {
  return await api.post(`/survey/${id}/complete`, data);
};

export const clearRequestedBooking = async ({ id }: { id?: string }) => {
  return await api.post(`/survey/${id}/booking/clear-requested`);
};

export const getAssessorSurveyBooking = async ({ id }: { id: string }) => {
  return await api.get(`/assessor/${id}/bookings`);
};

export const getSurveyBookingAvailability = async ({ start, end }: { start: Date; end: Date }) => {
  return await api.get(`/surveybooking/availability?start=${formatUtcDatetime(start)}&end=${formatUtcDatetime(end)}`);
};

export const putSurveyBooking = async (data: SurveyBookingForm) => {
  const response = await api.put(`/surveybooking`, data);
  return response;
};

export const patchSurveyBooking = async ({
  surveyBookingId,
  data,
}: {
  surveyBookingId?: string;
  data: SurveySaveBooking;
}) => {
  return await api.patch(`/surveybooking/${surveyBookingId}`, data);
};

export const getPayment = async ({ paymentId }: { paymentId: string }) => {
  return await api.get(`/payment/${paymentId}`);
};

export const postRefund = async ({
  paymentId,
  amountInPence,
  reference,
  refundReason,
  refundComments,
  idempotencyKey,
}: {
  paymentId: string;
  amountInPence: number;
  reference: string;
  refundReason: RefundReason;
  refundComments?: string;
  idempotencyKey?: string;
}) => {
  return await api.post(`/payment/${paymentId}/refund`, {
    amountInPence,
    totalAmountInPenceConfirmation: amountInPence,
    reference,
    refundReason,
    refundComments,
    idempotencyKey,
  });
};
