export enum PaymentStatus {
  Unknown = 0,
  Pending = 1,
  Paid = 2,
  Failed = 3,
  Cancelled = 4,
  PendingRefund = 5,
  Refunded = 6,
  ChargedBack = 7,
}

export type PaymentModel = {
  paymentId: string;
  description: string;
  chargeDate: Date;
  status: PaymentStatus;
  amount: number;
};

export enum RefundReason {
  ChangedMind = 1,
  TooExpensive = 2,
  GoneElsewhere = 3,
  NoLongerAvailable = 4,
  PreferredDateNotAvailable = 5,
  Other = 6,
  RatherNotSay = 7,
}
