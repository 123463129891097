import { Absence, AbsenceFormData } from '@epcbuilder/lib/models/assessors';
import { Booking } from '@epcbuilder/lib/models/booking';

const isAbsence = (slotStart: Date, slotEnd: Date, absenceStart: Date, absenceEnd: Date): boolean => {
  return !((slotStart < absenceStart && slotEnd < absenceStart) || (slotStart > absenceEnd && slotEnd > absenceEnd));
};

export const isAmAbsence = (absenceDate: Absence, date: Date) => {
  const slotStart = new Date(date.setUTCHours(8, 0, 0, 0));
  const slotEnd = new Date(date.setUTCHours(12, 0, 0, 0));

  const absenceStart = new Date(absenceDate.absenceDateStart);
  const absenceEnd = new Date(absenceDate.absenceDateEnd);

  return isAbsence(slotStart, slotEnd, absenceStart, absenceEnd);
};

export const isPmAbsence = (absenceDate: Absence, date: Date) => {
  const slotStart = new Date(date.setUTCHours(13, 0, 0, 0));
  const slotEnd = new Date(date.setUTCHours(17, 0, 0, 0));

  const absenceStart = new Date(absenceDate.absenceDateStart);
  const absenceEnd = new Date(absenceDate.absenceDateEnd);

  return isAbsence(slotStart, slotEnd, absenceStart, absenceEnd);
};

export const isFullDayAbsence = (absenceDate: Absence, date: Date) => {
  return isAmAbsence(absenceDate, date) && isPmAbsence(absenceDate, date);
};

export const checkBookingConflicts = (data: AbsenceFormData, bookings: Booking[]): boolean => {
  const selectedStartDate = new Date(data.absenceDateStart).toISOString().split('T')[0];
  const selectedEndDate = new Date(data.absenceDateEnd).toISOString().split('T')[0];
  const selectedEndSlot = data.slotEnd;

  let bookingConflict = false;

  bookings?.forEach((b) => {
    const surveyDate = new Date(b.surveyDate).toISOString().split('T')[0];
    const surveySlot = b.slot === 1 ? 'am' : 'pm';

    if (
      ((selectedStartDate < surveyDate && selectedEndDate > surveyDate) || // Booking is within the absence range
        (selectedStartDate <= surveyDate && selectedEndDate >= surveyDate)) && // Booking overlaps with the start or end of the absence
      !(
        (
          (selectedStartDate === surveyDate && selectedEndSlot === 'pm' && surveySlot === 'am') || // Allow PM absence if the booking is AM on the same day
          (selectedStartDate === surveyDate && selectedEndSlot === 'am' && surveySlot === 'pm')
        ) // Allow AM absence if the booking is PM on the same day
      )
    ) {
      bookingConflict = true;
    }
  });

  return bookingConflict;
};
