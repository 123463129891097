import React from 'react';

type TextAreaInputProps = JSX.IntrinsicElements['textarea'] & {
  className?: string;
  overrideBaseClassnames?: boolean;
  label?: string;
  error?: string;
};

export const TextAreaInput = React.forwardRef<HTMLTextAreaElement, TextAreaInputProps>((props, ref) => {
  const { label, error, name, className, overrideBaseClassnames, rows, ...rest } = props;

  const baseClassNames = `${className} focus:border-primary focus:dark:border-[#7AC2B9] outline-none border-blue-lighter dark:border-primary-darker bg-color-secondary focus:border-primary placeholder:text-neutral-dark rounded-xl border-2 px-4 py-2 text-base hover:shadow-grey-sm hover:border-blue-dark hover:dark:shadow-dark-sm hover:dark:border-primary disabled:border-[#CBD3D6] disabled:text-neutral-dark disabled:bg-[#F8F9FA] disabled:dark:border-[#85979E] disabled:dark:bg-dark-dark placeholder:text-neutral-dark w-full rounded-xl text-base`;
  return (
    <div className="relative flex w-full flex-col gap-2">
      {label && <p className="font-bold">{label}</p>}
      <textarea
        rows={rows}
        ref={ref}
        name={name}
        className={`no-scrollbar resize-none ${overrideBaseClassnames ? className : baseClassNames} ${!!error && 'dark:border-error-light hover:dark:border-error-light border-[#E76F51] hover:border-[#E76F51]'}`}
        aria-invalid={!!error}
        {...rest}
      />
      {error && (
        <div id={`${name}-error`} className="ml-2 flex flex-col">
          <p className="text-error dark:text-error-light text-xs">{error}</p>
        </div>
      )}
    </div>
  );
});

TextAreaInput.displayName = 'TextAreaInput';

export default TextAreaInput;
