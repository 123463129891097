import React from 'react';
import { AxiosResponse } from 'axios';
import { KeyedMutator } from 'swr';
import { JobReport } from '@/models/job';
import {
  deleteReportPropertyOverviewFloor1Plan,
  putReportPropertyOverviewFloor1Plan,
  putReportPropertyOverviewFloorPlan,
} from '@/network/report';
import { getImageKey } from '@/utils/getImageKey';
import { PropertyOverviewImage } from '../helpers';
import { IFloorPlans } from '../ReportPDF';

export const FloorPlan = ({
  savingReport,
  title,
  image,
  callback,
  className,
  jobReport,
  refetchJobReport,
  onDelete,
  showDeleteButton,
}: {
  savingReport: boolean;
  title: string;
  image?: string;
  callback: ({ id, data }: { id?: string; data: { value: string } }) => Promise<void | AxiosResponse<unknown, unknown>>;
  className: string;
  jobReport?: JobReport;
  refetchJobReport: KeyedMutator<JobReport>;
  onDelete?: ({ id }: { id?: string }) => Promise<void | AxiosResponse<unknown, unknown>>;
  showDeleteButton?: boolean;
}) => {
  const imageKeyFirstFloor = getImageKey({ image });

  return (
    <div className="flex flex-1 flex-col gap-2">
      <p className="absolute-top ml-4 text-lg font-bold">{title}</p>
      <div className="bg-light-dark h-[260px] w-full rounded-[16px] p-2 shadow-[0_5px_5px_rgba(38,70,83,0.1)]">
        <PropertyOverviewImage
          key={`FirstFloor-${imageKeyFirstFloor}`}
          savingReport={savingReport}
          title={title}
          image={image}
          imageClassName="object-contain bg-[#c7c7c7]"
          callback={callback}
          jobReport={jobReport}
          refetchJobReport={refetchJobReport}
          className={className}
          onDelete={onDelete}
          showDeleteButton={showDeleteButton}
        />
      </div>
    </div>
  );
};

const FloorPlansOverview = ({
  jobReport,
  savingReport,
  refetchJobReport,
  extraFloorPlans,
}: {
  jobReport?: JobReport;
  savingReport: boolean;
  refetchJobReport: KeyedMutator<JobReport>;
  extraFloorPlans: IFloorPlans;
}) => {
  const renderFloorPlan = (
    title: string,
    image: string | undefined,
    callback: (params: { id?: string; data: { value: string } }) => Promise<void | AxiosResponse<unknown, unknown>>,
    onDelete?: (params: { id?: string }) => Promise<void | AxiosResponse<unknown, unknown>>,
    showDeleteButton: boolean = false
  ) => (
    <FloorPlan
      title={title}
      image={image}
      callback={callback}
      jobReport={jobReport}
      refetchJobReport={refetchJobReport}
      savingReport={savingReport}
      className="bg-blue-light relative size-full overflow-hidden rounded-[12px]"
      onDelete={onDelete}
      showDeleteButton={showDeleteButton}
    />
  );

  if (extraFloorPlans.floor2Plan.show) {
    return renderFloorPlan('Ground Floor', jobReport?.propertyOverview.floorplan, putReportPropertyOverviewFloorPlan);
  }

  if (extraFloorPlans.floor1Plan.show && !extraFloorPlans.floor2Plan.show) {
    return (
      <div className="flex flex-row gap-4">
        {renderFloorPlan('Ground Floor', jobReport?.propertyOverview.floorplan, putReportPropertyOverviewFloorPlan)}
        {renderFloorPlan(
          'First Floor',
          extraFloorPlans.floor1Plan.image,
          putReportPropertyOverviewFloor1Plan,
          deleteReportPropertyOverviewFloor1Plan,
          Boolean(extraFloorPlans.floor1Plan.image)
        )}
      </div>
    );
  }

  return renderFloorPlan('Ground Floor', jobReport?.propertyOverview.floorplan, putReportPropertyOverviewFloorPlan);
};

export default FloorPlansOverview;
