export const isEmptyGuid = (guid?: string) => {
  return !guid || guid === '00000000-0000-0000-0000-000000000000';
};

/**
 * Formats a number as a GBP string. If the provided value has a decimal part,
 * the output will contain the decimal as pennies, otherwise the decimcal part
 * will be omitted. Standard rounding is applied when the input has more than 2
 * decimcal points of precison.
 *
 * Examples:
 *   5000 => £5000
 *   10.23 => £10.23
 *   20.2 => £20.20
 *   22.229 => £22.23
 */
export const convertNumberToGbpString = (value: number) => {
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: Number.isInteger(value) ? 0 : 2,
    maximumFractionDigits: Number.isInteger(value) ? 0 : 2,
  });
  return formatter.format(value);
};
