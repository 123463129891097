import { BookingOption } from '@epcbuilder/lib/models/generic';
import { BookingAvailability, SurveyBooking } from '@/models/job';

export const getSlotAvailabilityForDate = (
  slotAvailability: Array<BookingAvailability> | undefined,
  surveyDate: Date | undefined,
  surveyBooking: SurveyBooking | undefined
) => {
  const newAvailableSlots: Array<BookingOption> = [];

  const dateBookingAvailability = slotAvailability?.filter(
    (x) => x.date && surveyDate && new Date(x.date).toDateString() === surveyDate.toDateString()
  );

  const customerHasBookingForDate =
    surveyBooking?.surveyDate &&
    surveyDate &&
    new Date(surveyBooking.surveyDate).toDateString() === surveyDate.toDateString();

  if (
    dateBookingAvailability?.find((x) => x.slot === 1 && x.available) ||
    (customerHasBookingForDate && surveyBooking?.bookingSlot === 1)
  ) {
    newAvailableSlots.push({
      value: 1,
      label: 'AM',
    });
  }

  if (
    dateBookingAvailability?.find((x) => x.slot === 2 && x.available) ||
    (customerHasBookingForDate && surveyBooking?.bookingSlot === 2)
  ) {
    newAvailableSlots.push({
      value: 2,
      label: 'PM',
    });
  }

  return newAvailableSlots;
};

export const getDefaultSlot = (
  slotAvailability: Array<BookingOption>,
  surveyDate: Date | undefined,
  surveyBooking: SurveyBooking | undefined
) => {
  const customerHasBookingForDate =
    surveyBooking?.surveyDate &&
    surveyDate &&
    new Date(surveyBooking.surveyDate).toDateString() === surveyDate.toDateString();

  const singleSlotAvailable =
    slotAvailability.length === 1 && (slotAvailability[0].value === 1 || slotAvailability[0].value === 2)
      ? slotAvailability[0].value
      : undefined;

  return customerHasBookingForDate ? surveyBooking.bookingSlot : singleSlotAvailable;
};
