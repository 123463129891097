import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { Button } from '@epcbuilder/lib/components';
import { DeleteModal } from '@epcbuilder/lib/components/Modal';
import { Job, SurveyStatus } from '@epcbuilder/lib/models/jobs';
import { PaymentModel, PaymentStatus } from '@epcbuilder/lib/models/payments';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { format } from 'date-fns';
import useJobSurvey from '@/hooks/jobs/useJobSurvey';
import usePaymentStatus from '@/hooks/surveys/usePaymentStatus';
import useSurveyBooking from '@/hooks/surveys/useSurveyBooking';
import { SurveyBooking, SurveyInstallationStatuses } from '@/models/job';
import { postCreateJobSurvey } from '@/network/jobs';
import { deleteSurveyBooking } from '@/network/surveys';
import ClearRequestModal from './modals/ClearRequestModal';
import JobSurveyCompletionModal from './modals/JobSurveyCompletionModal';
import JobSurveyModal from './modals/JobSurveyModal';
import PaymentRefundModal from './modals/PaymentRefundModal';
import JobSurveyStatus from './JobSurveyStatus';

enum PaymentRenderMode {
  PaymentLoading,
  FetchFailed,
  NoPayment,
  PaymentPending,
  PaymentFailed,
  PaymentInside48Hours,
  PaymentOutside48Hours,
  PaymentPendingRefund,
  PaymentRefunded,
}

const JobSurveyData = ({
  job,
  refetchJob,
  surveyStatuses,
}: {
  job: Job | undefined;
  refetchJob: () => void;
  surveyStatuses: SurveyInstallationStatuses[];
}) => {
  const { jobSurvey, error: jobSurveyError, mutate: refetchJobSurvey } = useJobSurvey({ id: job?.id });
  const { surveyBooking, mutate: refetchSurveyBooking } = useSurveyBooking({ id: jobSurvey?.id });
  const {
    paymentStatus,
    mutate: refetchPaymentStatus,
    error: paymentStatusError,
    isLoading: paymentStatusLoading,
  } = usePaymentStatus({ paymentId: surveyBooking?.goCardlessPaymentId });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCompletionModal, setShowCompletionModal] = useState<boolean>(false);
  const [surveyCompletedLock, setSurveyCompletedLock] = useState<boolean>(false);
  const [showClearRequestModal, setShowClearRequestModal] = useState<boolean>(false);
  const [paymentRenderMode, setPaymentRenderMode] = useState<PaymentRenderMode>(PaymentRenderMode.PaymentLoading);
  const [showRefundModal, setShowRefundModal] = useState<boolean>(false);

  useEffect(() => {
    if (job?.pasHubJobId) {
      refetchJobSurvey();
    }
  }, [job, refetchJobSurvey]);

  useEffect(() => {
    setSurveyCompletedLock(
      (jobSurvey?.surveyStatusID &&
        jobSurvey.surveyStatusID != SurveyStatus.SURVEY_CANCELLED &&
        jobSurvey.surveyStatusID >= SurveyStatus.SURVEY_COMPLETED) ??
        false
    );
  }, [jobSurvey]);

  useEffect(() => {
    if (surveyBooking?.goCardlessPaymentId) {
      refetchPaymentStatus();
    }
  }, [surveyBooking, refetchPaymentStatus]);

  useEffect(() => {
    setPaymentRenderMode(
      getPaymentStatusFromResponse({
        paymentStatus,
        paymentStatusError,
        surveyBooking,
        isLoading: paymentStatusLoading,
      })
    );
  }, [paymentStatus, paymentStatusError, paymentStatusLoading, surveyBooking]);

  const getPaymentStatusFromResponse = ({
    paymentStatus,
    paymentStatusError,
    isLoading,
    surveyBooking,
  }: {
    paymentStatus: PaymentModel | undefined;
    paymentStatusError: string;
    isLoading: boolean;
    surveyBooking: SurveyBooking | undefined;
  }): PaymentRenderMode => {
    if (!surveyBooking?.goCardlessPaymentId || paymentStatus?.status === PaymentStatus.Unknown) {
      return PaymentRenderMode.NoPayment;
    }

    if (isLoading) {
      return PaymentRenderMode.PaymentLoading;
    }

    if (paymentStatusError) {
      return PaymentRenderMode.FetchFailed;
    }

    if (!paymentStatus) {
      return PaymentRenderMode.FetchFailed;
    }

    switch (paymentStatus.status) {
      case PaymentStatus.Pending:
        return PaymentRenderMode.PaymentPending;
      case PaymentStatus.Cancelled:
      case PaymentStatus.Failed:
      case PaymentStatus.ChargedBack:
        return PaymentRenderMode.PaymentFailed;
      case PaymentStatus.PendingRefund:
        return PaymentRenderMode.PaymentPendingRefund;
      case PaymentStatus.Refunded:
        return PaymentRenderMode.PaymentRefunded;
      case PaymentStatus.Paid: {
        if (
          // if inside 48 hours of surveyDate
          surveyBooking?.surveyDate &&
          Date.now() >= new Date(surveyBooking.surveyDate).setDate(new Date(surveyBooking.surveyDate).getDate() - 2)
        ) {
          return PaymentRenderMode.PaymentInside48Hours;
        }

        return PaymentRenderMode.PaymentOutside48Hours;
      }
      default:
        return PaymentRenderMode.FetchFailed;
    }
  };

  const renderTableRow = ({ key, value, index }: { key: string; value: string | undefined; index: number }) => {
    const isNotes = key.toLowerCase() === 'notes';

    return (
      <div key={index} className="border-neutral-dark mt-[-2px] flex flex-col items-center border-2 lg:flex-row">
        <p className="border-neutral-dark bg-primary/20 flex-1 self-stretch whitespace-normal break-all border-b-2 p-2 md:px-4 lg:border-b-0 lg:border-r-2 lg:bg-transparent">
          {key}
        </p>
        <p
          id={`access-${key.toLowerCase().replace(':', '').replaceAll(' ', '-')}`}
          className={`min-h-10 flex-1 self-stretch p-2 md:px-4 ${
            isNotes ? 'whitespace-pre-wrap break-words' : 'whitespace-normal break-all'
          }`}
        >
          {value}
        </p>
      </div>
    );
  };

  const renderPaidRow = ({ renderMode, index }: { renderMode: PaymentRenderMode; index: number }): ReactNode => {
    const renderLabel = () => {
      let label = '';

      // add cases for pendingRefund and refunded

      switch (renderMode) {
        case PaymentRenderMode.PaymentLoading:
          label = 'Payment loading...';
          break;
        case PaymentRenderMode.NoPayment:
          label = 'No';
          break;
        case PaymentRenderMode.FetchFailed:
          label = 'Failed to get payment';
          break;
        case PaymentRenderMode.PaymentFailed:
          label =
            paymentStatus?.amount && paymentStatus.paymentId
              ? `Payment failed (£${(paymentStatus.amount / 100).toFixed(2)}: ${paymentStatus.paymentId})`
              : surveyBooking?.goCardlessPaymentId
                ? `Payment failed (${surveyBooking?.goCardlessPaymentId})`
                : 'Payment failed';
          break;
        case PaymentRenderMode.PaymentPendingRefund:
          label = `Payment pending refund${paymentStatus?.paymentId ? ` (${paymentStatus.paymentId})` : ''}`;
          break;
        case PaymentRenderMode.PaymentRefunded:
          label = `Payment refunded${paymentStatus?.paymentId ? ` (${paymentStatus.paymentId})` : ''}`;
          break;
        case PaymentRenderMode.PaymentPending:
        case PaymentRenderMode.PaymentOutside48Hours:
        case PaymentRenderMode.PaymentInside48Hours:
        default:
          label =
            paymentStatus?.amount && paymentStatus?.paymentId
              ? `£${(paymentStatus.amount / 100).toFixed(2)} (${paymentStatus.paymentId})`
              : surveyBooking?.paid
                ? 'Yes'
                : 'No';
          break;
      }

      return <p id="access-paid">{label}</p>;
    };

    const renderButtons = () => {
      switch (renderMode) {
        case PaymentRenderMode.PaymentPending:
          return (
            <>
              <button
                data-tooltip-id="payment-processing"
                data-tooltip-place="bottom"
                className="opacity-50"
                disabled={true}
              >
                <b>Refund*</b>
              </button>
              <Tooltip id="payment-processing">
                <p className="text-center">
                  *Payment is still
                  <br />
                  being processed
                </p>
              </Tooltip>
            </>
          );
        case PaymentRenderMode.PaymentOutside48Hours:
          return (
            <button onClick={() => setShowRefundModal(true)}>
              <b>Refund</b>
            </button>
          );
        case PaymentRenderMode.PaymentInside48Hours:
          return (
            <>
              <button
                data-tooltip-id="payment-within-48-hours"
                data-tooltip-place="bottom"
                onClick={() => setShowRefundModal(true)}
              >
                <b>Refund*</b>
              </button>
              <Tooltip id="payment-within-48-hours">
                <p className="text-center">
                  *Survey is within 48 hours
                  <br />
                  from now, are you sure you
                  <br />
                  want to process a refund?
                </p>
              </Tooltip>
            </>
          );
        default:
          return null;
      }
    };

    return (
      <div key={index} className="border-neutral-dark mt-[-2px] flex flex-col items-center border-2 lg:flex-row">
        <p className="border-neutral-dark bg-primary/20 flex-1 self-stretch whitespace-normal break-all border-b-2 p-2 md:px-4 lg:border-b-0 lg:border-r-2 lg:bg-transparent">
          Paid
        </p>
        <div className="flex min-h-10 flex-1 justify-between self-stretch whitespace-normal break-all p-2 md:px-4">
          {renderLabel()}
          {renderButtons()}
        </div>
      </div>
    );
  };

  const data = {
    'Survey Date': surveyBooking?.surveyDate ? format(new Date(surveyBooking?.surveyDate), 'dd/MM/yyyy') : '',
    'Requested Slot': surveyBooking?.bookingSlot === 1 ? 'AM' : surveyBooking?.bookingSlot === 2 ? 'PM' : '',
    Paid: String(surveyBooking?.paid) || '', // this should have a preference for the GC Payment ID, then bool value
    Assessor: surveyBooking?.assessorName || '',
    Notes: surveyBooking?.notes,
  };

  const reportButtonDisabled = useMemo(
    () =>
      (jobSurvey?.surveyStatusID && jobSurvey?.surveyStatusID < SurveyStatus.SURVEY_COMPLETED) ||
      jobSurvey?.surveyStatusID === undefined,
    [jobSurvey]
  );

  const showClearRequestButton = useMemo(
    () =>
      jobSurvey?.surveyStatusID === SurveyStatus.SURVEY_REQUESTED &&
      jobSurvey.requestedBookingDate &&
      jobSurvey.requestedBookingSlot,
    [jobSurvey]
  );

  const createSurvey = useCallback(() => {
    if (job) {
      postCreateJobSurvey({ id: job.id }).then(() => refetchJobSurvey());
    }
  }, [job, refetchJobSurvey]);

  const handleDeleteSurveyBooking = async () => {
    if (surveyBooking) {
      try {
        await deleteSurveyBooking({ id: surveyBooking.id });
        window.location.reload();
      } catch (error) {
        handleUnknownDetail(error);
      }
    }
  };
  return (
    <>
      <div className="border-neutral-dark hover:border-primary relative gap-4 rounded-xl border-2 p-4">
        {jobSurveyError && (
          <div
            id="job-survey-error"
            className="absolute left-0 top-0 z-10 flex size-full items-center justify-center bg-[linear-gradient(-45deg,#c9e9e5_10%,transparent_10%,transparent_50%,#c9e9e5_50%,#c9e9e5_60%,transparent_60%,transparent)] bg-[length:8px_8px] dark:bg-[linear-gradient(-45deg,#0d695c_10%,transparent_10%,transparent_50%,#0d695c_50%,#0d695c_60%,transparent_60%,transparent)]"
          >
            <div className="bg-blue/90 dark:bg-primary-dark/90 border-dark/30 dark:border-light/30 max-w-[220px] rounded-[22px] border-2 p-4">
              <p className="text-center font-bold">There currently is no survey for this job.</p>
              <Button onClick={createSurvey}>Create</Button>
            </div>
          </div>
        )}
        {surveyCompletedLock && (
          <div className="absolute left-0 top-0 z-10 flex size-full items-center justify-center bg-[linear-gradient(-45deg,#c9e9e5_10%,transparent_10%,transparent_50%,#c9e9e5_50%,#c9e9e5_60%,transparent_60%,transparent)] bg-[length:8px_8px] dark:bg-[linear-gradient(-45deg,#0d695c_10%,transparent_10%,transparent_50%,#0d695c_50%,#0d695c_60%,transparent_60%,transparent)]">
            <div className="bg-blue/90 dark:bg-primary-dark/90 border-dark/30 dark:border-light/30 flex max-w-[220px] flex-col gap-2 rounded-[22px] border-2 p-4">
              <p className="text-center font-bold">This survey is currently completed and locked for editing.</p>
              <Button id="unlock-and-edit-survey-button" onClick={() => setSurveyCompletedLock(false)}>
                Unlock and Edit
              </Button>
            </div>
          </div>
        )}
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between">
            <h1>Survey details:</h1>
            <div className="flex flex-row items-center gap-2">
              {showClearRequestButton && (
                <Button style="secondary" onClick={() => setShowClearRequestModal(true)}>
                  Clear request
                </Button>
              )}
              <button
                type="button"
                id="delete-survey-details-icon"
                className="flex cursor-pointer items-center justify-center p-2"
                onClick={() => setShowDeleteModal(true)}
              >
                <MdDelete size={20} className="text-error" />
              </button>
              <button
                type="button"
                id="edit-survey-details-icon"
                className="flex cursor-pointer items-center justify-center p-2"
                onClick={() => setShowModal(true)}
              >
                <MdEdit size={20} className="text-primary" />
              </button>
            </div>
          </div>
          <JobSurveyStatus surveyStatuses={surveyStatuses} job={job} />
          <div className="mt-2 flex flex-col">
            <div className="flex flex-1 flex-col">
              {Object.entries(data).map(([key, value], index) =>
                key.toLowerCase() === 'paid'
                  ? renderPaidRow({ renderMode: paymentRenderMode, index })
                  : renderTableRow({ key, value, index })
              )}
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-col gap-2">
          <Button onClick={() => setShowCompletionModal(true)}>Mark survey as complete and generate report</Button>
        </div>
      </div>
      <Link
        to={`/properties/${job?.propertyId}/report`}
        className={reportButtonDisabled ? 'pointer-events-none' : ''}
        aria-disabled={reportButtonDisabled}
        tabIndex={reportButtonDisabled ? -1 : undefined}
      >
        <Button style="secondary" disabled={reportButtonDisabled}>
          Go to report
        </Button>
      </Link>
      {showModal && (
        <JobSurveyModal
          onClose={() => {
            refetchJob();
            refetchSurveyBooking();
            refetchJobSurvey();
            setShowModal(false);
          }}
          surveyBooking={surveyBooking}
          survey={jobSurvey}
        />
      )}
      {showDeleteModal && surveyBooking && (
        <DeleteModal
          value="survey booking details"
          onClose={() => setShowDeleteModal(false)}
          callback={handleDeleteSurveyBooking}
        />
      )}
      {showCompletionModal && (
        <JobSurveyCompletionModal
          onClose={() => setShowCompletionModal(false)}
          job={job}
          jobSurvey={jobSurvey}
          refetchJobSurvey={refetchJobSurvey}
        />
      )}
      {showClearRequestModal && (
        <ClearRequestModal
          onClose={() => setShowClearRequestModal(false)}
          jobSurvey={jobSurvey}
          refetchSurveyBooking={refetchSurveyBooking}
          refetchJobSurvey={refetchJobSurvey}
        />
      )}
      {showRefundModal && (
        <PaymentRefundModal
          onClose={() => setShowRefundModal(false)}
          paymentStatus={paymentStatus}
          refetchPaymentStatus={refetchPaymentStatus}
        />
      )}
    </>
  );
};

export default JobSurveyData;
