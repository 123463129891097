import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { Button, Modal } from '@epcbuilder/lib/components';
import { InstallationStatus } from '@epcbuilder/lib/models/jobs';
import { Property } from '@epcbuilder/lib/models/properties';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import download from 'downloadjs';
import { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import * as htmlToImage from 'html-to-image';
import { KeyedMutator } from 'swr';
import useJobInstallation from '@/hooks/jobs/useJobInstallation';
import useJobReport from '@/hooks/jobs/useJobReport';
import usePropertyActiveJob from '@/hooks/properties/usePropertyActiveJob';
import { getJobReportPdf, postJobReportPdf } from '@/network/jobs';
import { deleteReportPropertyOverviewFloor1Plan, deleteReportPropertyOverviewFloor2Plan } from '@/network/report';
import ReportPageExtraFloorPlans from './floor-plan/FloorPlansExtraPage';
import ReportPage1 from './ReportPage1';
import ReportPage2 from './ReportPage2';
import ReportPage3 from './ReportPage3';
import ReportPage4 from './ReportPage4';
import ReportPage5 from './ReportPage5';
import ReportPage6 from './ReportPage6';
import ReportPage7 from './ReportPage7';
//import ReportPage8 from './ReportPage8';
import ReportPage9 from './ReportPage9';
import ReportPage10 from './ReportPage10';
import ReportPage11 from './ReportPage11';

export interface IFloorPlans {
  floor1Plan: {
    show: boolean;
    updating: boolean;
    image?: string;
  };
  floor2Plan: {
    show: boolean;
    updating: boolean;
    image?: string;
  };
}

const ReportPDF = ({ property, refetchProperty }: { property: Property; refetchProperty: KeyedMutator<Property> }) => {
  const { job } = usePropertyActiveJob({ id: property?.id });
  const { jobReport, mutate: refetchJobReport, error: jobReportError } = useJobReport({ id: job?.id });
  const refs = useRef<(HTMLDivElement | null)[]>([]);
  const [savingReport, setSavingReport] = useState<boolean>(false);
  const [showCompletReportModal, setShowCompleteReportModal] = useState<boolean>(false);
  const { jobInstallation } = useJobInstallation({ id: job?.id });
  const [allowCompletion, setAllowCompletion] = useState<boolean>(false);
  const [extraFloorPlans, setExtraFloorPlans] = useState<IFloorPlans>({
    floor1Plan: {
      show: false,
      updating: false,
      image: undefined,
    },
    floor2Plan: {
      show: false,
      updating: false,
      image: undefined,
    },
  });

  useEffect(() => {
    if (
      jobInstallation &&
      ![InstallationStatus.NEW_INSTALLATION, InstallationStatus.INSTALLATION_CANCELLED].includes(
        jobInstallation.installationStatusId
      )
    ) {
      setAllowCompletion(false);
    } else {
      setAllowCompletion(true);
    }
  }, [jobInstallation]);

  useEffect(() => {
    // keeps state up to date with DB

    setExtraFloorPlans((current) => ({
      floor1Plan: {
        ...current.floor1Plan,
        show: current.floor1Plan.show || Boolean(jobReport?.propertyOverview?.floor1plan),
        image: jobReport?.propertyOverview?.floor1plan || undefined,
      },
      floor2Plan: {
        ...current.floor2Plan,
        show: current.floor2Plan.show || Boolean(jobReport?.propertyOverview?.floor2plan),
        image: jobReport?.propertyOverview?.floor2plan || undefined,
      },
    }));
  }, [jobReport?.propertyOverview?.floor1plan, jobReport?.propertyOverview?.floor2plan]);

  const downloadPdf = async () => {
    try {
      const pdfData = await getJobReportPdf({ id: job?.id });

      download(pdfData?.file, pdfData?.fileName, 'application/pdf');
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  };

  const completePdf = async () => {
    try {
      setSavingReport(true);
      const data: string[] = [];

      let fontEmbedCSS = null;
      for (const page of refs.current) {
        if (!page) continue;

        if (!fontEmbedCSS) {
          fontEmbedCSS = await htmlToImage.getFontEmbedCSS(page);
        }

        data.push(await htmlToImage.toPng(page, { pixelRatio: 2, includeQueryParams: true, fontEmbedCSS }));
      }

      await postJobReportPdf({ id: job?.id, data });
      toast.success('Report completed', { toastId: 'report-complete-success' });
    } catch (error: unknown) {
      handleUnknownDetail(error);
    } finally {
      setSavingReport(false);
    }
  };

  const [emblaRef, emblaApi] = useEmblaCarousel({ slidesToScroll: 1 }, []);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const nextSlide = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext();
    }
  }, [emblaApi]);

  const prevSlide = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev();
    }
  }, [emblaApi]);

  const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on('reInit', onInit);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onInit, onSelect]);

  if (jobReportError && jobReportError.detail === 'Report does not exist for this job') {
    return (
      <div className="flex h-96 flex-col items-center justify-center gap-4">
        <p>There is currently no report for this property. Please go to the job to generate one.</p>
        <Link to={`/properties/${property?.id}?tab=job`}>
          <Button>Property Job</Button>
        </Link>
      </div>
    );
  }

  const handleAddFloorPlan = () => {
    if (!extraFloorPlans.floor1Plan.show) {
      setExtraFloorPlans((current) => ({ ...current, floor1Plan: { ...current.floor1Plan, show: true } }));
    } else {
      setExtraFloorPlans((current) => ({ ...current, floor2Plan: { ...current.floor2Plan, show: true } }));
      nextSlide();
    }

    toast.success(`Floor plan added successfully`, { toastId: `add floor plan success` });
  };

  const handleDeleteFloorPlan = async () => {
    if (extraFloorPlans.floor1Plan.image) {
      setExtraFloorPlans((current) => ({ ...current, floor1Plan: { ...current.floor1Plan, updating: true } }));

      await deleteReportPropertyOverviewFloor1Plan({ id: jobReport?.id });

      setExtraFloorPlans((current) => ({
        ...current,
        floor1Plan: { ...current.floor1Plan, updating: false, image: undefined },
      }));
    }

    if (extraFloorPlans.floor2Plan.image) {
      setExtraFloorPlans((current) => ({ ...current, floor2Plan: { ...current.floor2Plan, updating: true } }));

      await deleteReportPropertyOverviewFloor2Plan({ id: jobReport?.id });

      setExtraFloorPlans((current) => ({
        ...current,
        floor2Plan: { ...current.floor2Plan, updating: false, image: undefined },
      }));
    }

    setExtraFloorPlans((current) => ({
      floor1Plan: { ...current.floor1Plan, show: false },
      floor2Plan: { ...current.floor2Plan, show: false },
    }));

    toast.success(`Floor plan removed successfully`, { toastId: `remove floor plan success` });
  };

  return (
    <>
      <div className="flex flex-col items-center gap-4">
        <div className="flex w-full flex-row justify-between gap-2">
          <Link to={`/properties/${property?.id}?tab=job`}>
            <Button style="secondary">Property Job</Button>
          </Link>
          <div className="flex flex-row gap-2">
            <Button style="secondary" onClick={() => downloadPdf()}>
              Download
            </Button>

            {allowCompletion ? (
              <Button onClick={() => setShowCompleteReportModal(true)}>Complete</Button>
            ) : (
              <>
                <Button data-tooltip-id="complete-button-tooltip" data-tooltip-place="bottom" disabled={true}>
                  Complete
                </Button>
                <Tooltip id="complete-button-tooltip">
                  <p>
                    Unable to recomplete report where
                    <br />
                    an installation is present (Unless
                    <br />
                    Pending or Cancelled).
                  </p>
                </Tooltip>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center justify-center gap-2">
          <button type="button" className="text-primary cursor-pointer" onClick={() => prevSlide()}>
            <MdKeyboardArrowLeft size={32} />
          </button>
          {scrollSnaps.map((_, index) => (
            <button
              type="button"
              id={`page-${index + 1}`}
              key={index}
              onClick={() => scrollTo(index)}
              className={`border-primary size-4 cursor-pointer rounded-full border-2 ${
                index === selectedIndex ? 'bg-primary' : 'bg-color'
              }`}
            />
          ))}
          <button type="button" className="text-primary cursor-pointer" onClick={() => nextSlide()}>
            <MdKeyboardArrowRight size={32} />
          </button>
        </div>
        <div className="w-full overflow-hidden" ref={emblaRef}>
          <div className="flex flex-row">
            <ReportPage1
              refs={refs}
              savingReport={savingReport}
              property={property}
              refetchProperty={refetchProperty}
            />
            <ReportPage2 refs={refs} />
            <ReportPage3
              refs={refs}
              savingReport={savingReport}
              jobReport={jobReport}
              refetchJobReport={refetchJobReport}
              onAddFloorPlan={handleAddFloorPlan}
              onDeleteFloorPlan={handleDeleteFloorPlan}
              extraFloorPlans={extraFloorPlans}
              nextSlide={nextSlide}
            />
            {extraFloorPlans.floor2Plan.show && (
              <ReportPageExtraFloorPlans
                refs={refs}
                savingReport={savingReport}
                jobReport={jobReport}
                refetchJobReport={refetchJobReport}
                extraFloorPlans={extraFloorPlans}
                setExtraFloorPlans={setExtraFloorPlans}
                prevSlide={prevSlide}
              />
            )}
            <ReportPage4
              refs={refs}
              savingReport={savingReport}
              jobReport={jobReport}
              refetchJobReport={refetchJobReport}
            />
            <ReportPage5
              refs={refs}
              savingReport={savingReport}
              jobReport={jobReport}
              refetchJobReport={refetchJobReport}
            />
            <ReportPage6 refs={refs} jobReport={jobReport} refetchJobReport={refetchJobReport} />
            <ReportPage7
              refs={refs}
              savingReport={savingReport}
              jobReport={jobReport}
              refetchJobReport={refetchJobReport}
            />
            {/*<ReportPage8 refs={refs} />*/}
            <ReportPage9 refs={refs} />
            <ReportPage10 refs={refs} />
            <ReportPage11 refs={refs} />
          </div>
        </div>
      </div>
      {showCompletReportModal && (
        <Modal onClose={() => setShowCompleteReportModal(false)}>
          <div className="flex flex-col gap-4">
            <p className="text-lg">Are you sure you want to complete this report?</p>
            <div className="flex flex-row justify-between gap-4">
              <Button style="secondary" onClick={() => setShowCompleteReportModal(false)}>
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  await completePdf();
                  setShowCompleteReportModal(false);
                }}
                loading={savingReport}
              >
                Complete
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ReportPDF;
