import { Option } from '../utils/generic';

export const slotBookingOptions: Option<number>[] = [
  { value: 1, label: 'AM' },
  { value: 2, label: 'PM' },
];

export const slotOptions: Option<number>[] = [
  { value: 1, label: '8:00 - 12:00 AM' },
  { value: 2, label: '13:00 - 17:00 PM' },
];
