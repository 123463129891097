import { PaymentModel } from '@epcbuilder/lib/models/payments';
import useSWR from 'swr';
import { getPayment } from '@/network/surveys';

const usePaymentStatus = ({ paymentId }: { paymentId?: string }) => {
  const { data, error, mutate, isLoading } = useSWR<PaymentModel>(
    paymentId ? `/payment/${paymentId}` : null,
    async () => (paymentId ? await getPayment({ paymentId }) : null)
  );

  return {
    paymentStatus: data,
    error,
    mutate,
    isLoading,
  };
};

export default usePaymentStatus;
