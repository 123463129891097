import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Loading, Modal, TextInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { Assessor, AssessorFormInput } from '@epcbuilder/lib/models/assessors';
import { EMAIL_REGEX, handleFormErrors, POSTCODE_REGEX } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { PHONE_REGEX } from '@epcbuilder/lib/utils/generic';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const assessorSchema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Invalid first name')
    .required('First Name must not be empty'),
  lastName: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Invalid last name')
    .required('Last Name must not be empty'),
  email: yup.string().matches(EMAIL_REGEX, 'Email is not a valid email address').required('Email must not be empty'),
  phoneNumber: yup
    .string()
    .matches(PHONE_REGEX, 'Phone is not a valid UK phone number')
    .required('Phone must not be empty'),
  officeAddress: yup.string().required('Office Address must not be empty'),
  officePostcode: yup
    .string()
    .required('Postcode must not be empty')
    .matches(POSTCODE_REGEX, 'Postcode is not a valid postcode'),
});

const AssessorFormModal = ({
  defaultValues,
  onClose,
  onSubmit,
  title,
  successMessage,
}: {
  defaultValues: AssessorFormInput;
  onClose: () => void;
  onSubmit: SubmitHandler<AssessorFormInput>;
  title: string;
  successMessage?: string;
}) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<AssessorFormInput>({
    defaultValues,
    resolver: yupResolver(assessorSchema),
    reValidateMode: 'onSubmit',
  });

  const handleFormSubmit: SubmitHandler<AssessorFormInput> = async (data) => {
    try {
      await onSubmit(data);
      toast.success(`${successMessage}`, { toastId: `${title.toLowerCase().replace(' ', '-')}-success` });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<Assessor>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  if (isSubmitting) {
    return <Loading />;
  }
  return (
    <Modal id="assessor-form-modal" onClose={onClose}>
      <h1 className="font-header text-2xl">{title}</h1>
      <form className="mt-4 flex flex-col">
        <p className="font-bold">First Name</p>
        <TextInput
          {...register('firstName')}
          id="firstName"
          name="firstName"
          placeholder="First Name"
          error={errors.firstName?.message}
          callbackOnChange={() => clearErrors('firstName')}
        />
        <p className="mt-4 font-bold">Last Name</p>
        <TextInput
          {...register('lastName')}
          id="lastName"
          name="lastName"
          placeholder="Last Name"
          error={errors.lastName?.message}
          callbackOnChange={() => clearErrors('lastName')}
        />
        {title !== 'Edit Assessor' && (
          <div>
            <p className="mt-4 font-bold">E-mail</p>
            <TextInput
              {...register('email')}
              id="email"
              name="email"
              placeholder="Email"
              error={errors.email?.message}
              callbackOnChange={() => clearErrors('email')}
            />
          </div>
        )}
        <p className="mt-4 font-bold">Phone Number</p>
        <TextInput
          {...register('phoneNumber')}
          id="phoneNumber"
          name="phoneNumber"
          placeholder="Phone Number"
          error={errors.phoneNumber?.message}
          callbackOnChange={() => clearErrors('phoneNumber')}
        />
        <p className="mt-4 font-bold">Office Address</p>
        <TextInput
          {...register('officeAddress')}
          id="officeAddress"
          name="officeAddress"
          placeholder="Office Address"
          error={errors.officeAddress?.message}
          callbackOnChange={() => clearErrors('officeAddress')}
        />
        <p className="mt-4 font-bold">Office Postcode</p>
        <TextInput
          {...register('officePostcode')}
          id="officePostcode"
          name="officePostcode"
          placeholder="Office Postcode"
          error={errors.officePostcode?.message}
          callbackOnChange={() => clearErrors('officePostcode')}
        />
        <div className="mt-4 flex justify-center gap-4">
          <NewButton id="cancel-button" variant="secondary" onClick={onClose} text="Cancel" />
          <NewButton
            id="assessor-submit"
            variant="primary"
            text="Save Changes"
            onClick={handleSubmit(handleFormSubmit)}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AssessorFormModal;
