import React from 'react';
import { BiShow } from 'react-icons/bi';
import { BsClipboardData } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { IconButton } from '@epcbuilder/lib/components';
import { JobStatusStrings } from '@epcbuilder/lib/models/jobs';
import { Property } from '@epcbuilder/lib/models/properties';
import { capitalize } from '@epcbuilder/lib/utils/generic';
import { format } from 'date-fns';

const PropertyRow = ({ property }: { property: Property }) => {
  return (
    <div
      id={property.id}
      className="border-neutral flex min-h-12 w-full flex-row items-center justify-between border-b-2 px-6"
    >
      <div
        className={`${property.potentialEligibleSchemeNames && property.potentialEligibleSchemeNames.length > 0 && 'eligible'} flex size-full min-h-20 flex-row gap-2`}
      >
        <div className="flex flex-1 flex-row gap-2 p-2">
          <div className={`mr-4 min-w-fit flex-1`}>
            <p id={`${property.id}-fullName`} className="flex h-full w-28 flex-row items-center gap-4">
              {property.userFullName}
            </p>
          </div>
          <div className={`mr-4 min-w-fit flex-1`}>
            <p id={`${property.id}-addressLine1`} className="flex h-full w-28 flex-row items-center gap-4">
              {property.addressLine1}
            </p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 2xl:flex`}>
            <p id={`${property.id}-addressLine2`} className="flex h-full w-28 flex-row items-center gap-4">
              {property.addressLine2}
            </p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 2xl:flex`}>
            <p id={`${property.id}-city`} className="flex h-full w-28 flex-row items-center gap-4">
              {property.city}
            </p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 xl:flex`}>
            <p id={`${property.id}-postcode`} className="flex h-full w-20 flex-row items-center gap-4">
              {property.postcode}
            </p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 lg:flex`}>
            <p id={`${property.id}-currentEpc`} className="flex h-full w-16 flex-row items-center gap-4">
              {property.currentEPCRating}
            </p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 xl:flex`}>
            <p id={`${property.id}-potentialEpc`} className="flex h-full w-16 flex-row items-center gap-4">
              {property.potentialEPCRating}
            </p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 sm:flex`}>
            <p id={`${property.id}-job-status`} className="w-30 flex h-full flex-row items-center gap-4">
              {property.jobStatus ? capitalize(JobStatusStrings[property.jobStatus]) : 'Unknown'}
            </p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 md:flex`}>
            <p id={`${property.id}-statusUpdatedOn`} className="flex h-full w-24 flex-row items-center gap-4">
              {format(new Date(property.statusUpdatedOn), 'dd/MM/yyyy')}
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-24 min-w-fit flex-row justify-end gap-4">
        {property.jobStatus && property.jobStatus >= 4 ? (
          <Link id={`${property.id}-report`} to={`/properties/${property.id}/report`}>
            <IconButton style="secondary">
              <BsClipboardData size={20} />
            </IconButton>
          </Link>
        ) : (
          <div className="w-[48px]"></div>
        )}
        <Link id={`${property.id}-view`} to={`/properties/${property.id}`}>
          <IconButton style="primary">
            <BiShow size={20} />
          </IconButton>
        </Link>
      </div>
    </div>
  );
};

export default PropertyRow;
