import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Modal, NewSelect, TextAreaInput } from '@epcbuilder/lib/components';
import { PaymentModel, RefundReason } from '@epcbuilder/lib/models/payments';
import { postRefund } from '@/network/surveys';

const PaymentRefundModal = ({
  onClose,
  paymentStatus,
  refetchPaymentStatus,
}: {
  onClose: () => void;
  paymentStatus: PaymentModel | undefined;
  refetchPaymentStatus: () => void;
}) => {
  enum RefundAmount {
    Half,
    Full,
  }

  const numberKeyRegex = /^\d+$/;

  const [refundAmountSelection, setRefundAmountSelection] = useState<RefundAmount>(RefundAmount.Full);

  interface IPaymentRefundForm {
    refundAmountSelection: RefundAmount;
    refundReason: RefundReason;
    refundComments: string;
  }

  const {
    register,
    setError,
    setValue,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IPaymentRefundForm>({
    defaultValues: {
      refundAmountSelection: RefundAmount.Full,
      refundReason: undefined,
      refundComments: '',
    },
  });

  useEffect(() => setValue('refundAmountSelection', refundAmountSelection), [setValue, refundAmountSelection]);

  const onSubmit: SubmitHandler<IPaymentRefundForm> = async (data) => {
    if (![RefundAmount.Full, RefundAmount.Half].includes(data.refundAmountSelection)) {
      setError('refundAmountSelection', { message: 'Refund amount must be either 100% or 50%' });
    }

    if (!data.refundReason) {
      setError('refundReason', { message: 'Refund reason must be selected' });
    }

    if (!paymentStatus?.paymentId) {
      setError('root', { message: 'Unable to submit: Payment Id not found' });
    }

    if (!paymentStatus?.amount) {
      setError('root', { message: 'Unable to submit: Payment Amount not found' });
    }

    if (
      ![RefundAmount.Full, RefundAmount.Half].includes(data.refundAmountSelection) ||
      !data.refundReason ||
      !paymentStatus?.paymentId ||
      !paymentStatus?.amount
    ) {
      return;
    }

    try {
      const refundAmount =
        data.refundAmountSelection === RefundAmount.Full ? paymentStatus.amount : Math.ceil(paymentStatus.amount / 2);

      await postRefund({
        paymentId: paymentStatus.paymentId,
        amountInPence: refundAmount,
        reference: '',
        refundReason: data.refundReason,
        refundComments: data.refundComments,
      });

      toast.success('Refund processed successfully', { toastId: 'survey-refund-success' });
      onClose();
      refetchPaymentStatus();
    } catch (error) {
      setError('root', { message: 'Error processing refund request' });
    }
  };

  return (
    <Modal id="payment-refund-modal" onClose={onClose}>
      <form className="flex flex-col gap-6">
        <h1 className="rounded-xl">Issue a Refund</h1>
        <div className="flex flex-col gap-2">
          <b>Paid</b>
          <div id="payment-reference" className={`flex justify-between rounded-xl border-2 p-3`}>
            <p>{`Payment Id: ${paymentStatus?.paymentId}`}</p>
            <b id="payment-id" className="leading-6">
              {paymentStatus?.amount ? `£${(paymentStatus?.amount / 100).toFixed(2)}` : ''}
            </b>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <b>Choose the refund amount:</b>
          <div className="flex flex-row gap-3">
            <button
              id="refund-amount-full"
              onClick={(e) => {
                e.preventDefault();
                setRefundAmountSelection(RefundAmount.Full);
              }}
              className={`dark:bg-primary w-full rounded-xl border-2 px-3 py-2 ${refundAmountSelection === RefundAmount.Full ? 'dark:border-light border-primary' : 'dark:border-dark-light border-blue-light opacity-80'}`}
            >
              <div className="flex w-full flex-col items-start">
                <p className="dark:text-light text-dark font-bold">
                  {paymentStatus?.amount ? `£${(paymentStatus.amount / 100).toFixed(2)}` : ''}
                </p>
                <p className="dark:text-neutral-light text-neutral-dark font-normal">100% Refund</p>
              </div>
            </button>
            <button
              id="refund-amount-half"
              onClick={(e) => {
                e.preventDefault();
                setRefundAmountSelection(RefundAmount.Half);
              }}
              className={`dark:bg-primary w-full rounded-xl border-2 px-3 py-2 ${refundAmountSelection === RefundAmount.Half ? 'dark:border-light border-primary' : 'dark:border-dark-light border-blue-light opacity-80'}`}
            >
              <div className="flex w-full flex-col items-start">
                <p className="dark:text-light text-dark font-bold">
                  {paymentStatus?.amount ? `£${(Math.ceil(paymentStatus.amount / 2) / 100).toFixed(2)}` : ''}
                </p>
                <p className="dark:text-neutral-light text-neutral-dark font-normal">50% Refund</p>
              </div>
            </button>
          </div>
          {errors.refundAmountSelection?.message && (
            <p className="text-error text-xs">{errors.refundAmountSelection.message}</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <b>Refund reason:</b>
          <NewSelect
            control={control}
            id="refundReason"
            name="refundReason"
            title="Select refund reason"
            placeholder="Select reason"
            className="rounded-xl"
            error={errors.refundReason?.message}
            options={Object.entries(RefundReason)
              .filter(([key]) => !numberKeyRegex.test(key))
              .map(([key, value]) => {
                return {
                  label: key
                    .toString()
                    .replace(/([A-Z])/g, ' $1')
                    .trim(),
                  value: value,
                };
              })}
          />
        </div>
        <div className="flex flex-col gap-2">
          <b>Comments:</b>
          <TextAreaInput id="notes" {...register('refundComments')} error={errors.refundComments?.message} />
        </div>
        <div className="flex flex-row gap-3">
          <Button disabled={isSubmitting} onClick={onClose} style="secondary" className="rounded-xl">
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
            className="rounded-xl"
          >
            Refund
          </Button>
        </div>
        {errors.root?.message && <p className="text-error text-xs">{errors.root.message}</p>}
      </form>
    </Modal>
  );
};

export default PaymentRefundModal;
