/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MdDelete, MdOutlineAdd, MdOutlineDriveFileRenameOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Button, NumberInput, TextInput } from '@epcbuilder/lib/components';
import Modal, { DeleteModal } from '@epcbuilder/lib/components/Modal';
import useImprovementCostCalculation from '@epcbuilder/lib/hooks/useImprovementCostCalculation';
import useImprovementQuickSelect from '@epcbuilder/lib/hooks/useImprovementQuickSelect';
import { ImprovementDto, ImprovementItem, ImprovementItemDto, Job, JobInstallation } from '@epcbuilder/lib/models/jobs';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import { KeyedMutator } from 'swr';
import * as yup from 'yup';
import useJobImprovements from '@/hooks/jobs/useJobImprovements';
import { deleteImprovementItem, postImprovementItem, updateImprovementItem } from '@/network/improvements';
import { deleteInstallationBookingImprovement, putInstallationBookingImprovement } from '@/network/installations';
import { postJobImprovement } from '@/network/jobs';
import ImprovementQuickSelect from '../improvements/ImprovementQuickSelect';
import { EpcModal } from './modals/EpcModal';

const IMPROVEMENT_COUNT = 3;
const TABLE_ROWS = 7;

const ImprovementType = ({
  improvement,
  itemIndex,
  refetchJobImprovements,
}: {
  improvement: ImprovementDto;
  itemIndex: number;
  refetchJobImprovements: KeyedMutator<ImprovementDto[]>;
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditItemModal, setshowEditItemModal] = useState<boolean>(false);

  const improvementItem = improvement.items[itemIndex];
  const LAST_ROW = itemIndex == TABLE_ROWS - 1;

  return (
    <>
      {improvementItem && (
        <tr className={`h-[36px] ${improvementItem?.improvementItemType?.isDiscount ? 'text-[#008080]' : ''}`}>
          <td
            className={`${!LAST_ROW && 'border-b-[1px]'} border-blue h-[36px] max-w-[1px] truncate border-r-[1px] p-2`}
          >
            {improvementItem?.itemText}
          </td>
          <td className={`${!LAST_ROW && 'border-b-[1px]'} border-blue h-[36px] border-r-[1px] p-2`}>
            {improvementItem?.improvementItemType?.isDiscount && '-'}
            {improvementItem?.itemCost && `£${improvementItem?.itemCost.toFixed(2)}`}
          </td>
          <td className={`${!LAST_ROW && 'border-b-[1px]'} border-blue h-[36px] border-r-[1px] p-2`}>
            {improvementItem?.co2Savings && `${improvementItem?.co2Savings.toFixed(2)} kg`}
          </td>
          <td className={`${!LAST_ROW && 'border-b-[1px]'} border-blue h-[36px] border-r-[1px] p-2`}>
            {improvementItem?.monetarySavings && `£${improvementItem?.monetarySavings.toFixed(2)}`}
          </td>
          <td className={`${!LAST_ROW && 'border-b-[1px]'} border-blue h-[36px] px-1`}>
            {improvementItem?.itemText && (
              <div className="gap2 flex">
                <button
                  id={`edit-item-${itemIndex}`}
                  type="button"
                  onClick={() => setshowEditItemModal(true)}
                  className="flex size-full cursor-pointer items-center justify-center"
                >
                  <MdOutlineDriveFileRenameOutline size={20} />
                </button>
                <button
                  id={`delete-item-${itemIndex}`}
                  type="button"
                  onClick={() => setShowDeleteModal(true)}
                  className="flex size-full cursor-pointer items-center justify-center"
                >
                  <MdDelete size={20} className="text-error" />
                </button>
              </div>
            )}
          </td>
        </tr>
      )}
      {showEditItemModal && improvementItem && (
        <EditImprovementItemModal
          improvementItem={improvementItem}
          refetchJobImprovements={refetchJobImprovements}
          onClose={() => setshowEditItemModal(false)}
        />
      )}

      {showDeleteModal && improvementItem && (
        <DeleteModal
          value={improvementItem.itemText}
          onClose={() => setShowDeleteModal(false)}
          callback={async () => {
            await deleteImprovementItem({ id: improvement.id, itemId: improvementItem.id });
            await refetchJobImprovements();
            setShowDeleteModal(false);
          }}
        />
      )}
    </>
  );
};

const improvementItemSchema = yup.object().shape({
  itemText: yup.string().required('Item must not be empty'),
  itemCost: yup.number().required('Cost must not be empty'),
  annualMonetarySavings: yup.number(),
  annualCo2Savings: yup.number(),
});

const EditImprovementItemModal = ({
  improvementItem,
  refetchJobImprovements,
  onClose,
}: {
  improvementItem: ImprovementItemDto;
  refetchJobImprovements: KeyedMutator<ImprovementDto[]>;
  onClose: () => void;
}) => {
  const defaultValues = {
    itemText: improvementItem.itemText ?? '',
    itemCost: improvementItem.itemCost ?? 0,
    co2Savings: improvementItem.co2Savings ?? 0,
    monetarySavings: improvementItem.monetarySavings ?? 0,
  };

  const availableQuickSelectTypes = useImprovementQuickSelect();
  const itemQuickAddModel = availableQuickSelectTypes.find(
    (x) => x.key.toLowerCase() == defaultValues.itemText?.toLowerCase()
  );
  const itemTextDisabled = (itemQuickAddModel && !itemQuickAddModel?.textEditable) ?? false;
  const itemCostDisabled = (itemQuickAddModel && !itemQuickAddModel?.costEditable) ?? false;
  const hideSavingsFields = (itemQuickAddModel && itemQuickAddModel?.hideSavingsFields) ?? false;

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ImprovementItem>({
    defaultValues: defaultValues,
    resolver: yupResolver(improvementItemSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<ImprovementItem> = async (data) => {
    try {
      await updateImprovementItem({ id: improvementItem?.id, data });
      await refetchJobImprovements();
      toast.success('Improvement item edited', { toastId: 'improvement-item-edit-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<ImprovementItem>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  return (
    <Modal onClose={onClose}>
      <p>Edit improvement item</p>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8 flex flex-col gap-4">
        <TextInput
          disabled={itemTextDisabled}
          id="itemText"
          {...register('itemText')}
          label="Item"
          error={errors.itemText?.message}
        />
        <NumberInput
          disabled={itemCostDisabled}
          id="itemCost"
          {...register('itemCost', {
            valueAsNumber: true,
          })}
          label="Cost"
          error={errors.itemCost?.message}
        />
        {!hideSavingsFields && (
          <NumberInput
            id="co2Savings"
            {...register('co2Savings', {
              valueAsNumber: true,
            })}
            label="Annual CO2 Savings (kg)"
            error={errors.co2Savings?.message}
          />
        )}
        {!hideSavingsFields && (
          <NumberInput
            id="monetarySavings"
            {...register('monetarySavings', {
              valueAsNumber: true,
            })}
            label="Annual Monetary Savings (£)"
            error={errors.monetarySavings?.message}
          />
        )}
        <div className="flex flex-row items-center justify-between gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="access-submit" loading={isSubmitting} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

const AddImprovementItemModal = ({
  improvement,
  refetchJobImprovements,
  onClose,
  itemText,
  itemCost,
}: {
  improvement: ImprovementDto;
  refetchJobImprovements: KeyedMutator<ImprovementDto[]>;
  onClose: () => void;
  itemText: string | undefined;
  itemCost: number;
}) => {
  const defaultValues = {
    itemText: itemText ?? '',
    itemCost: itemCost ?? 0,
  };

  const availableQuickSelectTypes = useImprovementQuickSelect();
  const itemQuickAddModel = availableQuickSelectTypes.find(
    (x) => x.key.toLowerCase() == defaultValues.itemText?.toLowerCase()
  );
  const itemTextDisabled = (itemQuickAddModel && !itemQuickAddModel?.textEditable) ?? false;
  const itemCostDisabled = (itemQuickAddModel && !itemQuickAddModel?.costEditable) ?? false;
  const hideSavingsFields = (itemQuickAddModel && itemQuickAddModel?.hideSavingsFields) ?? false;

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ImprovementItem>({
    defaultValues,
    resolver: yupResolver(improvementItemSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<ImprovementItem> = async (data) => {
    try {
      await postImprovementItem({ id: improvement?.id, data });
      await refetchJobImprovements();
      toast.success('Improvement item added', { toastId: 'improvement-item-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<ImprovementItem>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  return (
    <Modal onClose={onClose}>
      <p>Add new improvement item</p>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8 flex flex-col gap-4">
        <TextInput
          disabled={itemTextDisabled}
          id="itemText"
          {...register('itemText')}
          label="Item"
          error={errors.itemText?.message}
        />
        <NumberInput
          disabled={itemCostDisabled}
          id="itemCost"
          {...register('itemCost', {
            valueAsNumber: true,
          })}
          label="Cost"
          error={errors.itemCost?.message}
        />
        {!hideSavingsFields && (
          <NumberInput
            id="co2Savings"
            {...register('co2Savings', {
              valueAsNumber: true,
            })}
            label="Annual CO2 Savings (kg)"
            error={errors.co2Savings?.message}
          />
        )}
        {!hideSavingsFields && (
          <NumberInput
            id="monetarySavings"
            {...register('monetarySavings', {
              valueAsNumber: true,
            })}
            label="Annual Monetary Savings (£)"
            error={errors.monetarySavings?.message}
          />
        )}
        <div className="flex flex-row items-center justify-between gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="access-submit" loading={isSubmitting} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

const Improvement = ({
  job,
  jobInstallation,
  jobImprovements,
  value,
  index,
  refetchJobImprovements,
  refetchJobInstallation,
}: {
  job?: Job;
  jobInstallation?: JobInstallation;
  jobImprovements: ImprovementDto[];
  value: number;
  index: number;
  refetchJobImprovements: KeyedMutator<ImprovementDto[]>;
  refetchJobInstallation: KeyedMutator<JobInstallation>;
}) => {
  const improvement = jobImprovements[index];

  const [showAddNewItemModal, setShowAddNewItemModal] = useState<boolean>(false);
  const [showEpcModal, setShowEpcModal] = useState<boolean>(false);
  const [epcModalMode, setEpcModalMode] = useState<'add' | 'edit'>('add');
  const [improvementItem, setImprovementItem] = useState<ImprovementItem>();

  if (improvement) {
    const isUserSelected = jobInstallation?.selectedImprovementId === improvement.id;
    const isAdminSelected = jobInstallation?.adminSelectedImprovementId === improvement.id;

    let borderClasses = 'border-neutral';
    if (isUserSelected) {
      borderClasses = 'border-primary-lighter border-4';
    } else if (isAdminSelected) {
      borderClasses = 'border-primary border-4';
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const improvementCost = useImprovementCostCalculation(improvement.items);

    const handleUnselectImprovement = async () => {
      await deleteInstallationBookingImprovement({ id: jobInstallation?.id });
      await refetchJobInstallation();
    };

    const handleSelectImprovement = async () => {
      if (!improvement.newEpc) {
        setEpcModalMode('add');
        setShowEpcModal(true);
      } else if (jobInstallation?.id && improvement.newEpc) {
        await putInstallationBookingImprovement({ id: jobInstallation.id, improvementId: improvement.id });
        await refetchJobInstallation();
      }
    };

    return (
      <>
        <div className={`${borderClasses} relative flex w-full flex-col gap-2 rounded-[16px] border-2 p-2`}>
          <div className="border-neutral flex flex-row items-center">
            <p className="text-primary text-xl font-black">
              {value}
              {isAdminSelected && ' (admin selected)'}
              {isUserSelected && ' (user selected)'}
            </p>
            <p className="flex-1 text-center font-bold" id={`total-cost-${value}`}>
              Total cost: £{improvementCost.toFixed(2)}
            </p>
          </div>

          <div className="border-neutral relative flex flex-row items-center rounded-[12px] border-2 px-4">
            <p className="flex-1 text-center font-bold" id={`new-epc-${value}`}>
              New EPC: {improvement.newEpc || 'Unknown'}
            </p>
            <button
              id="edit-new-epc-icon"
              type="button"
              onClick={() => {
                setEpcModalMode('edit');
                setShowEpcModal(true);
              }}
              className="absolute right-0 cursor-pointer p-2"
            >
              <MdOutlineDriveFileRenameOutline size={24} />
            </button>
          </div>
          <table className="w-full">
            <colgroup>
              <col width="52%" />
              <col width="20%" />
              <col width="10%" />
              <col width="10%" />
              <col width="8%" />
            </colgroup>
            <tr>
              <td className="border-blue flex-1 border-b-2 border-r-[1px] p-2 font-bold">Measures</td>
              <td className="font-bol border-blue border-b-2 border-r-[1px] p-2">Cost</td>
              <td className="font-bol border-blue border-b-2 border-r-[1px] p-2">CO2 Savings/yr (kg)</td>
              <td className="font-bol border-blue border-b-2 border-r-[1px] p-2">Monetary Savings/yr (£)</td>
              <td className="border-blue w-6 border-b-2" />
            </tr>
            {Array.from({ length: TABLE_ROWS }).map((_, itemIndex) => (
              <ImprovementType
                key={`improvement-item-${itemIndex}`}
                improvement={improvement}
                itemIndex={itemIndex}
                refetchJobImprovements={refetchJobImprovements}
              />
            ))}
          </table>
          <div className="flex gap-4">
            <Button
              id={`add-new-item-button-${value}`}
              style="secondary"
              onClick={() => {
                setImprovementItem(undefined);
                setShowAddNewItemModal(true);
              }}
            >
              Add new item
            </Button>
            <ImprovementQuickSelect
              setImprovementItem={setImprovementItem}
              improvement={improvement}
              refetchJobReport={refetchJobImprovements}
              setShowAddImprovementItem={setShowAddNewItemModal}
              selectType="Button"
            />
            {isUserSelected ? (
              <Button id={`unselect-improvement-button-${value}`} style="primary" onClick={handleUnselectImprovement}>
                Unselect
              </Button>
            ) : (
              <Button
                id={`select-improvement-button-${value}`}
                style="primary"
                onClick={handleSelectImprovement}
                disabled={improvement.items.length === 0}
              >
                Select
              </Button>
            )}
          </div>
        </div>

        {showAddNewItemModal && (
          <AddImprovementItemModal
            itemText={improvementItem?.itemText == 'Improvement Item' ? '' : improvementItem?.itemText ?? ''}
            itemCost={improvementItem ? improvementItem.itemCost : 0}
            improvement={improvement}
            refetchJobImprovements={refetchJobImprovements}
            onClose={() => setShowAddNewItemModal(false)}
          />
        )}
        {showEpcModal && (
          <EpcModal
            improvement={improvement}
            jobInstallationId={jobInstallation?.id}
            onClose={() => setShowEpcModal(false)}
            refetchJobImprovements={refetchJobImprovements}
            onEpcSelected={async () => {
              await putInstallationBookingImprovement({ id: jobInstallation?.id, improvementId: improvement.id });
              await refetchJobInstallation();
            }}
            mode={epcModalMode}
          />
        )}
      </>
    );
  }

  return (
    <button
      key={index}
      type="button"
      onClick={async () => {
        await postJobImprovement({ id: job?.id });
        refetchJobInstallation();
        refetchJobImprovements();
      }}
      className="border-neutral hover:border-primary text-primary flex h-[130px] w-full cursor-pointer items-center justify-center rounded-[16px] border-2"
    >
      <MdOutlineAdd size={64} />
    </button>
  );
};

const JobInstallationImprovements = ({
  job,
  jobInstallation,
  refetchJobInstallation,
}: {
  job?: Job;
  jobInstallation?: JobInstallation;
  refetchJobInstallation: KeyedMutator<JobInstallation>;
}) => {
  const { jobImprovements, mutate: refetchJobImprovements } = useJobImprovements({ id: job?.id });

  return (
    <>
      <div className="mt-4 flex flex-col gap-4">
        <p className="text-lg">Upgrade options</p>
        <div className="flex flex-col justify-between gap-4">
          {jobImprovements &&
            Array.from({ length: IMPROVEMENT_COUNT }, (_, index) => index + 1).map((value, index) => (
              <Improvement
                key={`improvement-${index}`}
                job={job}
                jobInstallation={jobInstallation}
                jobImprovements={jobImprovements}
                value={value}
                index={index}
                refetchJobImprovements={refetchJobImprovements}
                refetchJobInstallation={refetchJobInstallation}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default JobInstallationImprovements;
