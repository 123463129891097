import { QuickSelectImprovementItem } from '../models/jobs';

const useImprovementQuickSelect = () => {
  const availableQuickSelectTypes: QuickSelectImprovementItem[] = [];

  //Add the options for the quick select here.
  //If default value is 0, then it will redirect to the add improvement scheme with the item name pre-filled.
  //If the default value is not 0, then it will add the improvement item directly.
  availableQuickSelectTypes.push({
    key: 'Improvement Item',
    value: 1,
    defaultCost: 0,
    textEditable: true,
    costEditable: true,
  });
  availableQuickSelectTypes.push({
    key: 'Great British Insulation Scheme',
    value: 2,
    defaultCost: 0,
    textEditable: false,
    costEditable: true,
    hideSavingsFields: true,
  });
  availableQuickSelectTypes.push({
    key: 'Boiler Upgrade Scheme',
    value: 3,
    defaultCost: 7500,
    textEditable: false,
    costEditable: false,
    hideSavingsFields: true,
  });
  availableQuickSelectTypes.push({
    key: 'Assessment Rebate',
    value: 4,
    defaultCost: 0,
    textEditable: false,
    costEditable: true,
    hideSavingsFields: true,
  });
  availableQuickSelectTypes.push({
    key: 'Discount',
    value: 5,
    defaultCost: 0,
    textEditable: true,
    costEditable: true,
    hideSavingsFields: true,
  });

  return availableQuickSelectTypes;
};

export default useImprovementQuickSelect;
