import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Children } from '../utils';

export const Accordion = ({
  children,
  onClick,
  accordionOpen,
  accordionChildren,
}: {
  children?: Children;
  onClick?: () => void;
  accordionOpen?: boolean;
  accordionChildren?: Children;
}) => {
  return (
    <>
      <button
        type="button"
        className={`${
          accordionOpen && 'border-b-2'
        } border-neutral flex min-h-20 w-full flex-row items-center justify-between p-4 ${onClick && 'cursor-pointer'}`}
        onClick={onClick}
      >
        {children}
      </button>
      {accordionOpen && <div className="flex min-h-28 flex-row items-center p-4">{accordionChildren}</div>}
    </>
  );
};

//This will be our accordion moving forward
export const NewAccordion = ({
  children,
  onClick,
  accordionOpen,
  accordionChildren,
  accordionButtonClassName,
  accordionBodyClassName,
  id,
}: {
  children?: Children;
  onClick?: () => void;
  accordionOpen?: boolean;
  accordionChildren?: Children;
  accordionButtonClassName?: string;
  accordionBodyClassName?: string;
  id?: string;
}) => {
  const baseAccordionButtonClassName = `${
    accordionOpen && 'border-b-2'
  } border-blue dark:border-primary flex w-full flex-row items-center justify-between p-2 ${onClick && 'cursor-pointer'}`;
  const finalAccordionButtonClassName = twMerge(baseAccordionButtonClassName, accordionButtonClassName);

  const baseAccordionBodyClassName = 'flex min-h-20 flex-row items-center';
  const finalAccordionBodyClassName = twMerge(baseAccordionBodyClassName, accordionBodyClassName);

  return (
    <>
      <button id={id} type="button" className={finalAccordionButtonClassName} onClick={onClick}>
        {children}
      </button>
      {accordionOpen && <div className={finalAccordionBodyClassName}>{accordionChildren}</div>}
    </>
  );
};
